<template>
  <div class="app-container">
    <div class="page-title">
      <h2>{{ $t('produit.produits') }}</h2>
    </div>
    <el-row :gutter="40" class="panel-group">
      <el-col :xs="12" :sm="12" :lg="6" class="card-panel-col">
        <div class="card-panel">
          <div class="card-panel-icon-wrapper icon-people">
            <h1>‍🌾</h1>
          </div>
          <div class="card-panel-description">
            <div class="card-panel-text">
              Total Produits
            </div>
            <count-to :start-val="0" :end-val="total" :duration="2600" class="card-panel-num" />
          </div>
        </div>
      </el-col>
    </el-row>
    <el-card v-if="checkRole(['admin'])" class="box-card filter-card just-for-admin">
      <div slot="header" class="clearfix">
        <span>{{ $t('general.filter') }}</span>
        <el-button style="float: right; padding: 3px 0" type="text" @click="initSearch">{{ $t('general.init') }}</el-button>
      </div>
      <div class="filter-container">
        <!-- <el-radio v-model="activeList" label="actives" border @change="getSubCampagneagricolesList('indexactive')">{{ $t('general.F_actives') }}</el-radio>-->
        <el-radio v-model="activeList" label="desactives" border @change="getSubProduitsList('indexdesactive')">{{ $t('general.F_desactives') }}</el-radio>
        <el-radio v-model="activeList" label="disponibles" border @change="handleFilterProduits">{{ $t('general.disponibles') }}</el-radio>
        <el-radio v-model="activeList" label="corbeille" class="danger-radio" border @change="getSubProduitsList('indexinvisible')">{{ $t('general.corbeille') }}</el-radio>
      </div>
    </el-card>

    <el-card class="box-card">
      <div class="filter-container">
        <el-input v-model="query.keyword" :placeholder="$t('general.keyword')" class="wd-200 mr-15 filter-item" @input="handleFilterProduits" />
        <el-button v-waves class="mr-15 filter-item" type="primary" icon="el-icon-search" @click="handleFilterProduits">
          {{ $t('general.search') }}
        </el-button>
        <el-button v-waves :loading="downloading" class="filter-item" type="primary" icon="el-icon-download" @click="handleDownload">
          {{ $t('general.export') }}
        </el-button>
        <el-button v-if="checkPermission(['creer produit'])" class="mr-15 filter-item" type="primary" icon="el-icon-plus" @click="handlecreateProduit">
          {{ $t('general.add') }}
        </el-button>
        <el-button v-if="checkPermission(['creer produit'])" class="mr-15 filter-item" type="primary" icon="el-icon-plus" @click="drawer=true">
          Importer
        </el-button>
      </div>

      <!-- ======= REGION PRODUCT TABLE ======= -->
      <!-- Product Table Data  -->
      <el-table v-loading="loading" :data="list" border fit highlight-current-row style="width: 100%">
        <!-- el-table-column align="center" label="Code" min-width="100px">
          <template slot-scope="scope">
            <span>{{ scope.row.code }}</span>
          </template>
        </el-table-column -->

        <el-table-column align="center" :label="$t('general.nom')" min-width="200px">
          <template slot-scope="scope">
            <span>{{ scope.row.nom }}</span>
          </template>
        </el-table-column>

        <el-table-column align="center" :label="$t('general.description')" min-width="300px">
          <template slot-scope="scope">
            <span>{{ scope.row.description }}</span>
          </template>
        </el-table-column>
        <!-- v-if="activeList!='corbeille' && checkPermission(['activer campagneagricole'])" -->
        <el-table-column v-if="checkPermission(['activer produit'])" align="center" :label="$t('general.status')" width="100">
          <template slot-scope="scope">
            <el-switch
              style="display: block"
              active-color="#13ce66"
              inactive-color="#ff4949"
              :active-value="true"
              :inactive-value="false"
              :value="scope.row.is_active"
              @change="setProduitStatus(scope.row.id,'active')"
            />
          </template>
        </el-table-column>

        <el-table-column v-if="checkRole(['admin']) && (activeList==='corbeille' || query.keyword)" align="center" :label="$t('general.restaurer')" width="100">
          <template slot-scope="scope">
            <el-switch
              v-if="!scope.row.is_visible"
              style="display: block"
              active-color="#13ce66"
              inactive-color="#ff4949"
              :active-value="true"
              :inactive-value="false"
              :value="scope.row.is_visible"
              @change="setProduitStatus(scope.row.id,'visible')"
            />
          </template>
        </el-table-column>

        <el-table-column v-if="checkPermission(['modifier produit','visible produit','supprimer produit'])" align="center" :label="1==1 ? $t('general.actions') : $t('general.suppressionDefinitive')" width="200">
          <template slot-scope="scope">
            <el-button v-if="scope.row.is_visible && checkPermission(['modifier produit'])" type="primary" size="small" icon="el-icon-edit" style="margin-right:10px;" @click="handleEditProduit(scope.row.id);" />
            <el-button v-if="checkPermission(['visible produit','supprimer produit'])" slot="reference" type="danger" size="small" icon="el-icon-delete" style="margin-right:10px;" @click="handleDeleteProduit(scope.row.id, scope.row.is_visible, scope.row.nom);" />
            <el-button type="info" size="small" icon="el-icon-view" @click="showDetailsModal(scope.row)" />
          </template>
        </el-table-column>
      </el-table>

      <pagination v-if="total > query.limit" :total="total" :page.sync="query.page" :limit.sync="query.limit" @pagination="getProduitsList" />

      <!-- ======= REGION USER DIALOG ======= -->
      <!-- Dialogue for edit and add product -->
      <el-dialog
        :title="editing ? $t('produit.edit') + ' ' + currentProduit.nom : $t('produit.AddProduit')"
        :visible.sync="dialogCreateProduit"
        :before-close="handleCloseCampagneagricoleCreateDraw"
        :loading="produitCreating"
        width="30%"
      >
        <div class="content-inputs">
          <el-form ref="produitForm" :rules="rules" :model="currentProduit" label-position="left">
            <el-form-item :label="$t('produit.nom')" prop="nom">
              <el-input v-model="currentProduit.nom" />
            </el-form-item>
            <el-form-item :label="$t('general.description')" prop="description">
              <el-input v-model="currentProduit.description" type="textarea" />
            </el-form-item>
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <div style="text-align:right;">
            <el-button plain type="danger" @click="dialogCreateProduit=false">
              {{ $t('permission.cancel') }}
            </el-button>
            <el-button type="primary" :loading="produitCreating" @click="editing ? updateProduit() : createProduit()">
              {{ produitCreating ? $t('general.saving') : $t('general.save') }}
            </el-button>
          </div>
        </span>
      </el-dialog>

      <!-- Dialogue to show product detail-->
      <el-dialog
        :title="detailDataM !== null? 'Détails du produit : '+detailDataM.nom: 'Détails'"
        :visible.sync="detailM"
        :before-close="handleCloseCampagneagricoleCreateDraw"
        :loading="produitCreating"
        width="30%"
      >
        <div v-if="detailDataM !== null" class="content-inputs">
          <el-form ref="produitForm" :rules="rules" :model="detailDataM" label-position="left">
            <el-form-item :label="$t('produit.nom')" prop="nom">
              <el-input v-model="detailDataM.nom" />
            </el-form-item>
            <el-form-item :label="$t('general.description')" prop="description">
              <el-input v-model="detailDataM.description" type="textarea" />
            </el-form-item>
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <div style="text-align:right;">
            <el-button plain type="info" @click="detailM=false">
              {{ $t('permission.cancel') }}
            </el-button>
            <!-- <el-button plain type="primary" @click="print">
              Imprimer
            </el-button> -->
          </div>
        </span>
      </el-dialog>
      <div v-show="false">
        <table>
          <tr>
            <th>Company</th>
            <th>Contact</th>
            <th>Country</th>
          </tr>
          <tr>
            <td>Alfreds Futterkiste</td>
            <td>Maria Anders</td>
            <td>Germany</td>
          </tr>
          <tr>
            <td>Centro comercial Moctezuma</td>
            <td>Francisco Chang</td>
            <td>Mexico</td>
          </tr>
        </table>
      </div>

      <el-drawer
        :title="detailData[0]!== undefined ? 'Détails du produit : '+detailData[0].nom:'Titre'"
        :visible.sync="detail"
        direction="rtl"
        size="40%"
      >
        <div class="demo-drawer__content">
          <el-table :data="detailData">
            <!-- el-table-column property="code" label="Code" width="150" /-->
            <el-table-column property="nom" label="Nom" width="200" />
            <el-table-column property="description" label="description" />
          </el-table>

          <div>
            <h4>Parcelles sur lesquelles il est cultivé : </h4>
          </div>
          <el-table :data="detailData">
            <!-- el-table-column property="code" label="Code" width="150" /-->
            <el-table-column property="nom" label="Nom" width="200" />
            <el-table-column property="description" label="description" />
          </el-table>
          <div class="demo-drawer__footer" style="margin-top:'auto'">
            <el-button type="primary">Imprimer</el-button>
          </div>
        </div>
      </el-drawer>
      <el-dialog
        title="Importation"
        :visible.sync="drawer"
        :before-close="handleCloseCampagneagricoleCreateDraw"
        width="60%"
      >
        <upload-excel-component :on-success="handleSuccess" :before-upload="beforeUpload" />
        <el-table :data="tableData" border highlight-current-row style="width: 100%;margin-top:20px;">
          <el-table-column v-for="item of tableHeader" :key="item" :prop="item" :label="item" />
        </el-table>
        <el-button type="success" :disabled="!tableData.length>0" @click="bulkInsert">Insérer</el-button>
      </el-dialog>

      <!-- ======= REGION USER DIALOG ======= -->

    </el-card>
  </div>
</template>

<script>
import Pagination from '@/components/Pagination'; // Pagination
import Resource from '@/api/resource';
import waves from '@/directive/waves'; // Directives
import permission from '@/directive/permission'; //  directive Permission
import role from '@/directive/role'; // Directives Role
import checkPermission from '@/utils/permission'; // Vérification Permission
import checkRole from '@/utils/role'; // Vérification Roles
import moment from 'moment';
import UploadExcelComponent from '@/components/UploadExcel/index.vue';
import CountTo from 'vue-count-to';
// import Vue from 'vue';
// import VueHtmlToPaper from 'vue-html-to-paper';
// const options = {
//   name: '_blank',
//   specs: [
//     'fullscreen=yes',
//     'titlebar=yes',
//     'scrollbars=yes',
//   ],
//   styles: [
//     'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
//     'https://unpkg.com/kidlat-css/css/kidlat.css',
//   ],
//   timeout: 1000, // default timeout before the print window appears
//   autoClose: true, // if false, the window will not close after printing
//   windowTitle: window.document.title, // override the window title
// };
// Vue.use(VueHtmlToPaper, options);

const produitResource = new Resource('produits');
// const bulkProduitResource = new Resource('produits-import');
export default {
  name: 'ProduitsList',
  components: { UploadExcelComponent, Pagination, CountTo },
  directives: { waves, permission, role },
  data() {
    return {
      tableData: [],
      tableHeader: [],
      drawer: false,
      list: null,
      total: 0,
      detail: false,
      detailData: [],
      detailM: false,
      detailDataM: null,
      loading: true,
      downloading: false,
      produitCreating: false,
      editing: false,
      activeList: 'disponibles',
      currentProduit: {},
      query: {
        page: 1,
        limit: 100,
        keyword: '',
      },
      dialogCreateProduit: false,
      currentProduitId: 0,
      rules: {
        nom: [{ required: true, message: this.$t('produit.NomRequired'), trigger: 'blur' }],
      },
      fileList: [],
    };
  },
  computed: {

  },
  created() {
    this.resetNewProduit();
    this.getProduitsList();
  },
  methods: {
    checkPermission,
    checkRole,
    isEmptyArray(array){
      if (typeof array !== 'undefined' && array.length === 0) {
        return true;
      }
    },
    showDetails(row) {
      var newArray = [];
      console.log(row);
      newArray.push(row);
      this.detailData = newArray;
      this.detail = true;
    },
    showDetailsModal(row){
      this.detailDataM = row;
      this.detailM = true;
    },
    bulkInsert(){
      console.log('LES ENTETES : ', this.tableHeader);
      var validHeaders = this.tableHeader.length === 2 && this.tableHeader[0] === 'nom' && this.tableHeader[1] === 'description';
      if (validHeaders) {
        console.log('DATA : ', this.tableData);
        const data = { 'produits': this.tableData };
        console.log('FORM DATA : ', data);
        /* bulkProduitResource
          .store(data)
          .then(async(response) => {
            this.$message({
              message: this.$t('produit.NewProduit') + this.$t('produit.iscreatedSuccessfully'),
              type: 'success',
              duration: 5 * 1000,
            });
            // this.currentProduitId = response.data.id;
            this.resetNewProduit();
            this.dialogCreateProduit = false;
            this.handleFilterProduits();
          })
          .catch(error => {
            console.log(error);
          })
          .finally(() => {
            this.produitCreating = false;
            this.drawer = false;
            this.tableHeader = [];
            this.tableData = [];
          });*/
        // for (let index = 0; index < this.tableData.length; index++) {
        //   const element = this.tableData[index];
        //   console.log('A INSERER : ', element);
        //   if (element.nom !== '') {
        //     produitResource
        //       .store(element)
        //       .then(async(response) => {
        //         console.log('index ', index, response.data);
        //       })
        //       .catch(error => {
        //         console.log(error);
        //       })
        //       .finally(() => {
        //         this.drawer = false;
        //         this.tableHeader = [];
        //         this.tableData = [];
        //       });
        //   }
        // }
        console.log('ENTETES VALIDE');
      } else {
        this.$message({
          message: 'DONNEES NON CONFORMES',
          type: 'info',
          duration: 5 * 1000,
        });
      }
    },
    handleCloseCampagneagricoleCreateDraw(done) {
      this.$confirm(this.$t('general.closingWarningText'))
        .then(_ => {
          done();
        })
        .catch(_ => {});
    },
    cancelproduitForm() {
      this.$refs.CampagneagricoleCreationDrawer.closeDrawer();
    },
    async setProduitStatus(id, type){
      const data = await produitResource.get('toogle_' + type + '/' + id);
      if (data.success){
        this.$message({
          type: 'success',
          message: this.$t('general.statusUpdated'),
        });
        this.initSearch();
      } else {
        this.$message({
          type: 'danger',
          message: this.$t('general.statusUpdateError'),
        });
      }
    },
    async getSubProduitsList(subUrl) {
      this.loading = true;
      const { data } = await produitResource.sublist('/' + subUrl, this.query);
      this.list = data;
      this.loading = false;
    },
    initSearch(){
      this.handleFilterProduits();
    },
    beforeUpload(file) {
      const isLt1M = file.size / 1024 / 1024 < 1;

      if (isLt1M) {
        return true;
      }

      this.$message({
        message: 'Please do not upload files larger than 1m in size.',
        type: 'warning',
      });
      return false;
    },
    handleSuccess({ results, header }) {
      this.tableData = results;
      this.tableHeader = header;
    },
    async getProduitsList() {
      this.loading = true;
      const { data, meta } = await produitResource.list(this.query);
      // this.list = data.reverse();
      this.list = data;
      this.total = meta.total;
      console.log('===== EDI');
      console.log(data);
      console.log('===== EVO');
      this.loading = false;
    },
    async getSubCampagneagricolesList(subUrl) {
      this.loading = true;
      const { data } = await produitResource.sublist('/' + subUrl, this.query);
      this.list = data;
      this.loading = false;
    },
    handleFilterProduits() {
      this.query.page = 1;
      this.getProduitsList();
    },
    handlecreateProduit() {
      this.editing = false;
      this.resetNewProduit();
      this.dialogCreateProduit = true;
      this.$nextTick(() => {
        this.$refs['produitForm'].clearValidate();
      });
    },
    handleEditProduit(id){
      this.editing = true;
      this.resetNewProduit();
      this.currentProduit = this.list.find(abonne => abonne.id === id);
      this.dialogCreateProduit = true;
      this.$nextTick(() => {
        this.$refs['produitForm'].clearValidate();
      });
    },
    handleDeleteProduit(id, visible, nom) {
      console.log('id : ' + id + 'nom : ' + nom);
      this.$confirm(this.$t('produit.deleteWarn') + ' ' + nom + '. ' + this.$t('general.deleteContinue'), 'Warning', {
        confirmButtonText: 'Oui',
        cancelButtonText: this.$t('general.cancel'),
        type: 'warning',
      }).then(() => {
        console.log('result : ');
        if (!visible && (checkRole(['admin']) || checkPermission(['supprimer produit']))) {
          console.log('result 2 : ');
          this.$swal({
            title: this.$t('general.SureQuestion'),
            text: this.$t('general.irrversibleMessage'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: this.$t('general.ouiSupprimer'),
          }).then((result) => {
            if (result.isConfirmed) {
              produitResource.destroy(id).then(response => {
                this.$message({
                  type: 'success',
                  message: this.$t('produit.suppressionEffectue'),
                });
                this.initSearch();
              }).catch(error => {
                console.log(error);
              });
            }
          });
        } else {
          this.setProduitStatus(id, 'visible');
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: this.$t('general.suppressionAnnule'),
        });
      });
    },
    createProduit() {
      this.$refs['produitForm'].validate((valid) => {
        if (valid) {
          this.produitCreating = true;
          produitResource
            .store(this.currentProduit)
            .then(async(response) => {
              this.$message({
                message: this.$t('produit.NewProduit') + ' ' + this.currentProduit.nom + ' ' + this.$t('produit.iscreatedSuccessfully'),
                type: 'success',
                duration: 5 * 1000,
              });
              this.currentProduitId = response.data.id;
              this.resetNewProduit();
              this.dialogCreateProduit = false;
              this.handleFilterProduits();
            })
            .catch(error => {
              console.log(error);
            })
            .finally(() => {
              this.produitCreating = false;
            });
        } else {
          console.log('Erreur !!');
          return false;
        }
      });
    },
    updateProduit() {
      this.$refs['produitForm'].validate((valid) => {
        if (valid) {
          this.produitCreating = true;
          produitResource.update(this.currentProduit.id, this.currentProduit).then(response => {
            this.resetNewProduit();
            this.handleFilterProduits();
            this.$message({
              message: this.$t('produit.isupdatedSuccessfully'),
              type: 'success',
              duration: 5 * 1000,
            });
            this.dialogCreateProduit = false;
            this.editing = false;
          })
            .catch(error => {
              console.log(error);
            })
            .finally(() => {
              this.produitCreating = false;
            });
        } else {
          console.log('Erreur !!');
          return false;
        }
      });
    },
    resetNewProduit() {
      this.currentProduit = {
        nom: '',
        description: '',
      };
    },
    handleDownload() {
      this.downloading = true;
      import('@/vendor/Export2Excel').then(excel => {
        const tHeader = ['ID', this.$t('produit.nom'), this.$t('general.description')];
        const filterVal = ['id', 'nom', 'description'];
        const data = this.formatJson(filterVal, this.list);
        excel.export_json_to_excel({
          header: tHeader,
          data,
          filename: 'Liste des produits SCIAGRI_' + moment().format('DD-MM-YYYY_hh-mm'),
        });
        this.downloading = false;
      });
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => v[j]));
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
    onChange(file, fileList){
      var a = this.fileList;
      a.push({ name: file.name, url: file.url });
      // console.log('onChange begin');
      this.fileList = a;
      // console.log(this.fileList);
      // console.log('onChange end');
    },
  },
};
</script>

<style lang="scss" scoped>
.panel-group {
  margin-top: 18px;

  .card-panel-col {
    margin-bottom: 32px;
  }

  .card-panel {
    height: 108px;
    cursor: pointer;
    font-size: 12px;
    position: relative;
    overflow: hidden;
    color: #666;
    background: #fff;
    box-shadow: 4px 4px 40px rgba(0, 0, 0, .05);
    border-color: rgba(0, 0, 0, .05);

    &:hover {
      .card-panel-icon-wrapper {
        color: #fff;
      }

      .icon-people {
        background: #40c9c6;
      }

      .icon-message {
        background: #36a3f7;
      }

      .icon-money {
        background: #f4516c;
      }

      .icon-shopping {
        background: #34bfa3
      }
    }

    .icon-people {
      color: #40c9c6;
    }

    .icon-message {
      color: #36a3f7;
    }

    .icon-money {
      color: #f4516c;
    }

    .icon-shopping {
      color: #34bfa3
    }

    .card-panel-icon-wrapper {
      float: left;
      margin: 14px 0 0 14px;
      padding: 16px;
      transition: all 0.38s ease-out;
      border-radius: 6px;
    }

    .card-panel-icon {
      float: left;
      font-size: 48px;
    }

    .card-panel-description {
      float: right;
      font-weight: bold;
      margin: 26px;
      margin-left: 0px;

      .card-panel-text {
        line-height: 18px;
        color: rgba(0, 0, 0, 0.45);
        font-size: 16px;
        margin-bottom: 12px;
      }

      .card-panel-num {
        font-size: 20px;
      }
    }
  }
}

@media (max-width:550px) {
  .card-panel-description {
    display: none;
  }

  .card-panel-icon-wrapper {
    float: none !important;
    width: 100%;
    height: 100%;
    margin: 0 !important;

    .svg-icon {
      display: block;
      margin: 14px auto !important;
      float: none !important;
    }
  }
}
.edit-input {
  padding-right: 100px;
}
.cancel-btn {
  position: absolute;
  right: 15px;
  top: 10px;
}
.dialog-footer {
  text-align: left;
  padding-top: 0;
  margin-left: 150px;
}
.app-container {
  flex: 1;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
  .block {
    float: left;
    min-width: 250px;
  }
  .clear-left {
    clear: left;
  }
}
</style>
